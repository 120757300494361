import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  HiBan,
  HiOutlineCheck,
  HiShieldCheck,
  HiShieldExclamation,
} from "react-icons/hi";

import useSWRMutation from "swr/mutation";
import { sendRequestPost } from "../../swr";

import InputText from "../../components/Form/InputText";
import InputDate from "../../components/Form/InputDate";
import InputSelect from "../../components/Form/InputSelect";
import InputNumber from "../../components/Form/InputNumber";
import CardSim from "../../components/CardSim";
import { API_URL_TES } from "../../config";
import Loading from "../Loading";
import Policy from "../../components/Policy";
import ModalAll from "../../components/Modal/ModalAll";

const Register = () => {
  const [idRegister, setIdRegister] = useState("");
  const [katSim, setKatSim] = useState("");
  const [nik, setNik] = useState("");
  const [nikConfirm, setNikConfirm] = useState("");
  const [namaLengkap, setNamaLengkap] = useState("");
  const [tmpLahir, setTmpLahir] = useState("");
  const [tglLahir, setTglLahir] = useState("");
  const [jenKel, setJenKel] = useState("");
  const [noHp, setNoHp] = useState("");
  const [alamat, setAlamat] = useState("");
  const [jenSim, setJenSim] = useState("");
  const [pdTerakhir, setPdTerakhir] = useState("");

  const [modalShow, setModalShow] = useState(false);
  const [modalSuccess, setModalSuccess] = useState(false);
  const [titleModal, setTitleModal] = useState("");
  const [iconModal, setIconModal] = useState("");
  const [contentModal, setContentModal] = useState("");

  const [errors, setErrors] = useState([]);

  const navigate = useNavigate();

  const { trigger, isMutating } = useSWRMutation(
    `${API_URL_TES}/pemohon/registrasi`,
    sendRequestPost
  );

  const { trigger: triggerFind, isMutating: isMutatingFind } = useSWRMutation(
    `${API_URL_TES}/pemohon/find`,
    sendRequestPost,
    {
      onSuccess: (data) => {
        // Jika sukses, tampilkan data
        setNik(data.nik);
        setNamaLengkap(data.namaLengkap);
        setTmpLahir(data.tmpLahir);
        setTglLahir(data.tglLahir);
        setJenKel(data.jenKel);
        setNoHp(data.noHp);
        setAlamat(data.alamat);
        setPdTerakhir(data.pdTerakhir);
      },
      onError: (error) => {
        // jika error dengan menampilkan pesan sesuai status error
        if (error.status === 400) {
          setModalShow(true);
          setTitleModal("Opps!");
          setIconModal(
            <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10 text-red-500">
              <HiBan size={24} />
            </div>
          );
          setContentModal(error.info.message);
        } else if (error.status === 422) {
          setNamaLengkap("");
          setTmpLahir("");
          setTglLahir(error.info.data.tglLahir);
          setJenKel(error.info.data.jenKel);
          setNoHp("");
          setAlamat(error.info.data.alamat);
          setPdTerakhir("");

          setModalShow(true);
          setTitleModal("NIK Response!");
          setIconModal(
            <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-orange-100 sm:mx-0 sm:h-10 sm:w-10 text-orange-500">
              <HiShieldExclamation size={24} />
            </div>
          );
          setContentModal(error.info.message);
        } else if (error.status === 500) {
          setNamaLengkap("");
          setTmpLahir("");
          setTglLahir("");
          setJenKel("");
          setNoHp("");
          setAlamat("");
          setPdTerakhir("");

          setModalShow(true);
          setTitleModal("NIK Response!");
          setIconModal(
            <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10 text-red-500">
              <HiBan size={24} />
            </div>
          );
          setContentModal(error.info.message);
        } else {
          setModalShow(true);
          setTitleModal("Opps!");
          setIconModal(
            <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10 text-red-500">
              <HiBan size={24} />
            </div>
          );
          setContentModal("Maaf, silahkan ulangi kembali beberapa saat!");
        }
      },
    }
  );

  const kelamin = [
    {
      name: "Laki-Laki",
      value: "L",
    },
    {
      name: "Perempuan",
      value: "P",
    },
  ];

  const itemSim = [
    {
      name: "SIM A",
      value: "A",
    },
    {
      name: "SIM B",
      value: "B",
    },
    {
      name: "SIM C",
      value: "C",
    },
    {
      name: "SIM D",
      value: "D",
    },
  ];

  const jenis = [
    {
      name: "Baru",
      value: "Baru",
    },
    {
      name: "Perpanjangan",
      value: "Perpanjangan",
    },
    {
      name: "Peningkatan",
      value: "Peningkatan",
    },
    {
      name: "Penurunan",
      value: "Penurunan",
    },
    {
      name: "Hilang",
      value: "Hilang",
    },
    {
      name: "Rusak",
      value: "Rusak",
    },
  ];

  const itemPd = [
    {
      name: "Tidak Sekolah",
      value: "-",
    },
    {
      name: "SD",
      value: "SD",
    },
    {
      name: "SLTP/SMP/Sederajat",
      value: "SMP",
    },
    {
      name: "SLTA/SMA/Sederajat",
      value: "SMA",
    },
    {
      name: "Diploma 1",
      value: "D1",
    },
    {
      name: "Diploma 2",
      value: "D2",
    },
    {
      name: "Diploma 3",
      value: "D3",
    },
    {
      name: "Diploma 4",
      value: "D4",
    },
    {
      name: "S1",
      value: "S1",
    },
    {
      name: "S2",
      value: "S2",
    },
    {
      name: "S3",
      value: "S3",
    },
  ];

  const simpan = async (e) => {
    e.preventDefault();

    try {
      const response = await trigger({
        nik: nik,
        nikConfirm: nikConfirm,
        namaLengkap: namaLengkap,
        tmpLahir: tmpLahir,
        tglLahir: tglLahir,
        jenKel: jenKel,
        noHp: noHp,
        alamat: alamat,
        katSim: katSim,
        jenSim: jenSim,
        pdTerakhir: pdTerakhir,
      });

      setModalSuccess(true);
      setTitleModal("Success!");
      setIconModal(
        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-emerald-100 sm:mx-0 sm:h-10 sm:w-10 text-emerald-500">
          <HiShieldCheck size={24} />
        </div>
      );
      setContentModal(response.message);
      setIdRegister(response._id);
    } catch (error) {
      if (error.status === 400) {
        setModalShow(!modalShow);
        setTitleModal("Opps!");
        setIconModal(
          <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10 text-red-500">
            <HiBan size={24} />
          </div>
        );
        setContentModal(error.info.message);
      } else if (error.status === 422) {
        setErrors(error.info.errors);

        setModalShow(true);
        setTitleModal("Opps!");
        setIconModal(
          <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10 text-red-500">
            <HiBan size={24} />
          </div>
        );
        setContentModal(error.info.message);
      } else {
        setModalShow(true);
        setTitleModal("Opps!");
        setIconModal(
          <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10 text-red-500">
            <HiBan size={24} />
          </div>
        );
        setContentModal("Maaf, silahkan ulangi kembali beberapa saat lagi!");
      }
    }
  };

  const findPemohon = async (e) => {
    e.preventDefault();
    try {
      await triggerFind({ nik: nik });
    } catch (error) {
      // console.error(error); // Log untuk debugging jika perlu
    }
  };

  if (isMutating)
    return (
      <Loading pesanLoading="Mohon Tunggu, sedang menyiapkan halaman tes..." />
    );

  return (
    <>
      <div className="container mx-auto py-10 mt-10 lg:mt-14">
        <div className="grid grid-cols-12 grid-flow-col gap-3">
          <div className="lg:col-start-4 lg:col-span-6 col-start-1 col-span-12 bg-white p-4 rounded-lg shadow-lg">
            {/* form */}
            <h3 className="font-poppins-semibold md:text-lg lg:mt-4 mt-2 text-indigo-600">
              Register Tes Di Outlet
            </h3>
            <p className="text-sm font-poppins-regular text-gray-600">
              Silahkan isi dengan lengkap data Anda dibawah ini kemudian klik
              register untuk melanjutkan registrasi tes di lokasi.
            </p>

            <form onSubmit={simpan}>
              <div className="grid gap-3 mb-6 grid-cols-6 mt-10">
                <div className="col-start-1 md:col-span-3 mb-3 col-span-6">
                  <div className="flex flex-row items-center">
                    <div className="flex-1">
                      <InputNumber
                        label="Nomor Induk Kependudukan"
                        id="nik"
                        value={nik}
                        setValue={setNik}
                        placeholder="Masukkan NIK Anda di sini"
                        errors={errors}
                        eventBlur={findPemohon}
                      />
                    </div>
                    {isMutatingFind && (
                      <div className="ml-2">
                        <label className="block mb-2 text-xs font-poppins-semibold">
                          &nbsp;
                        </label>
                        <div role="status">
                          <svg
                            aria-hidden="true"
                            className="w-4 h-4 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                            viewBox="0 0 100 101"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                              fill="currentColor"
                            />
                            <path
                              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                              fill="currentFill"
                            />
                          </svg>
                          <span className="sr-only">Loading...</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="md:col-span-3 col-span-6 mb-3">
                  <InputNumber
                    label="Input Ulang NIK"
                    id="nikConfirm"
                    value={nikConfirm}
                    setValue={setNikConfirm}
                    placeholder="Masukkan Konfirmasi NIK Anda di sini"
                    errors={errors}
                  />
                </div>
                <div className="col-start-1 md:col-span-6 col-span-6 mb-3">
                  <InputText
                    label="Nama Lengkap"
                    id="namaLengkap"
                    value={namaLengkap}
                    setValue={setNamaLengkap}
                    placeholder="Masukkan nama lengkap Anda di sini"
                    errors={errors}
                  />
                </div>
                <div className="col-start-1 md:col-span-4 col-span-3 mb-3">
                  <InputText
                    label="Tempat lahir"
                    id="tmpLahir"
                    value={tmpLahir}
                    setValue={setTmpLahir}
                    placeholder="Masukkan tempat lahir Anda di sini"
                    errors={errors}
                  />
                </div>
                <div className="md:col-span-2 col-span-3 mb-3">
                  <InputDate
                    label="Tanggal lahir"
                    id="tglLahir"
                    value={tglLahir}
                    setValue={setTglLahir}
                    placeholder="07/03/1993"
                    errors={errors}
                  />
                </div>
                <div className="col-start-1 md:col-span-3 col-span-6 mb-3">
                  <InputSelect
                    label="Jenis Kelamin"
                    name="jenKel"
                    id="jenKel"
                    value={jenKel}
                    setValue={setJenKel}
                    options={kelamin}
                    errors={errors}
                  />
                </div>
                <div className="md:col-span-3 col-span-6 mb-3">
                  <InputNumber
                    label="Nomor Handphone"
                    id="noHp"
                    value={noHp}
                    setValue={setNoHp}
                    placeholder="08xx"
                    errors={errors}
                  />
                </div>
                <div className="col-start-1 md:col-span-3 col-span-6 mb-3">
                  <InputSelect
                    label="Pendidikan Terakhir"
                    name="pdTerakhir"
                    id="pdTerakhir"
                    value={pdTerakhir}
                    setValue={setPdTerakhir}
                    options={itemPd}
                    errors={errors}
                  />
                </div>
                <div className="col-start-1 md:col-span-6 col-span-6 mb-3">
                  <InputText
                    label="Alamat"
                    id="alamat"
                    value={alamat}
                    setValue={setAlamat}
                    placeholder="Masukkan alamat lengkap Anda"
                    errors={errors}
                  />
                </div>
                <div className="col-start-1 md:col-span-6 col-span-6 mb-3">
                  <label className="block mb-2 text-sm font-poppins-medium">
                    PILIH PERMOHONAN SIM
                  </label>
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-3">
                    {itemSim &&
                      itemSim.map((item, index) => (
                        <CardSim
                          key={`kat-${index}`}
                          name={item.name}
                          value={item.value}
                          setValue={katSim}
                          onClick={() => setKatSim(item.value)}
                        />
                      ))}
                  </div>
                </div>
                <div className="col-start-1 md:col-span-3 col-span-6 mb-3">
                  <InputSelect
                    label="Jenis SIM"
                    name="jenSim"
                    id="jenSim"
                    value={jenSim}
                    setValue={setJenSim}
                    options={jenis}
                    errors={errors}
                  />
                </div>
                <div className="col-start-1 md:col-span-6 col-span-6 mb-3">
                  <h2 className="font-poppins-semibold text-lg text-center text-black mb-2">
                    Kebijakan PSIKOLOGI SIM Online
                  </h2>
                  <p className="text-xs mb-3 font-poppins-regular text-gray-500 lg:mx-8">
                    Dengan ini saya menyatakan setuju pada kebijakan psikologi
                    sim online dibawah ini:
                  </p>
                  <Policy />

                  <div className="flex flex-row lg:mx-8 mt-3">
                    <div className="flex items-center mb-4">
                      <input
                        id="default-checkbox"
                        type="checkbox"
                        value=""
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                        required
                      />
                      <label
                        htmlFor="default-checkbox"
                        className="ml-3 text-xs font-poppins-regular text-gray-500"
                      >
                        <span className="font-poppins-semibold">
                          Saya menyetujui
                        </span>{" "}
                        semua kebijakan diatas.{" "}
                        <span className="text-blue-500">
                          Silahkan baca kembali kebijakan terlebih dahulu
                          sebelum melanjutkan.
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <button
                type="submit"
                disabled={isMutating}
                className="text-white bg-[#3b5998] hover:bg-[#3b5998]/90 active::bg-[#3b5998]/50  focus:outline-none focus:ring-[#3b5998]/50 font-poppins-medium rounded-lg text-sm px-5 py-3 text-center inline-flex items-center justify-center shadow-md w-full"
              >
                <span className="ml-2">
                  <HiOutlineCheck size={20} />
                </span>
                <span>Register</span>
              </button>
            </form>
          </div>
        </div>
      </div>

      {modalShow && (
        <ModalAll
          title={titleModal}
          icon={iconModal}
          content={contentModal}
          btnRefClose={() => setModalShow(false)}
        />
      )}

      {modalSuccess && (
        <ModalAll
          title={titleModal}
          icon={iconModal}
          content={contentModal}
          btnNext={() => navigate(`/detailregister/${idRegister}`)}
        />
      )}
    </>
  );
};

export default Register;
