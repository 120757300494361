import React, { useEffect } from "react";
import {
  HiOutlineCursorClick,
  HiOutlineDocumentText,
  HiOutlinePhone,
  HiOutlineQrcode,
} from "react-icons/hi";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import CardMenu from "../../components/CardMenu";

import LogoEmas from "../../assets/svg/logo-emas.svg";

const Home = () => {
  const MySwal = withReactContent(Swal);

  useEffect(() => {
    if (!checkLocalStorage()) {
      MySwal.fire({
        icon: "error",
        title: "Maaf Ada Kendala!",
        text: "Maaf Anda belum mengaktifkan permission data storage di browser. Silahkan ke Setting > Setelan Situs > Data Situs di perangkat, aktifkan Permission Data Storage browser Anda. Reload/Refresh browser Kemudian ulangi kembali!",
        confirmButtonText: "Lanjutkan",
      });

      localStorage.clear();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkLocalStorage = () => {
    const test = "test";
    try {
      localStorage.setItem(test, test);
      localStorage.removeItem(test);
      return true;
    } catch (error) {
      return false;
    }
  };

  return (
    <div className="container mx-auto py-10 mt-10 lg:mt-14">
      <div className="flex flex-row justify-center">
        <img src={LogoEmas} alt="PSISIMONLINE" width={120} />
      </div>
      <div className="grid grid-cols-12 grid-flow-col gap-3">
        <div className="lg:col-start-4 lg:col-span-6 col-start-1 col-span-12 p-4 max-sm:mx-2 text-center">
          <h3 className="font-poppins-semibold md:text-lg text-white">
            Selamat Datang di PSISIM Online!
          </h3>
          <p className="text-sm font-poppins-regular text-gray-50">
            Aplikasi Tes Psikologi SIM Online
          </p>
        </div>
      </div>

      <div className="grid grid-cols-12 gap-3 mt-4 max-sm:mx-2">
        <div className="lg:col-start-2 lg:col-span-10 col-start-1 col-span-12">
          {/* grid menu */}
          <div className="grid grid-cols-12 gap-3">
            <CardMenu
              namaMenu="Tes PSI Online"
              descMenu="Silahkan klik untuk melakukan tes online"
              icon={<HiOutlineCursorClick size={28} />}
              to="/daftar"
            />
            <CardMenu
              namaMenu="Cari Hasil Tes"
              descMenu="Silahkan klik untuk mendownload hasil tes"
              icon={<HiOutlineQrcode size={28} />}
              to="/carihasil/tesonline"
            />
            <CardMenu
              namaMenu="Tes PSI Di Outlet"
              descMenu="Silahkan klik untuk registrasi tes di tempat"
              icon={<HiOutlineDocumentText size={28} />}
              to="/register"
            />
            <CardMenu
              namaMenu="Konseling"
              descMenu="Gagal tes, buat janji konseling dengan konselor"
              icon={<HiOutlinePhone size={28} />}
              to="/konseling"
            />
          </div>
          {/* end grid menu */}
        </div>
      </div>

      <div className="text-center font-poppins-regular text-xs text-gray-200 mt-10">
        <p>Copyright &copy; {new Date().getFullYear()} | All rights reserved</p>
        <p>IT PSIKOLOGI SIM ONLINE</p>
      </div>
    </div>
  );
};

export default Home;
