import React from "react";
import { useParams } from "react-router-dom";

import useSWR from "swr";

import { HiOutlineDocumentDownload } from "react-icons/hi";

import DocOfflinepdf from "./DocOfflinepdf";
import { PDFDownloadLink } from "@react-pdf/renderer";
import Loading from "../../components/Loading";
import NotUpdate from "../ErrorPage/NotUpdate";

const fetcher = (url) => fetch(url).then((r) => r.json());

const HasilOffline = () => {
  const { id } = useParams();

  const { data, error, isLoading } = useSWR(
    `https://apipayment1.psisimonline.id/pemohon/detail-hasil/${id}`,
    fetcher,
    {
      loadingTimeout: 3000,
    }
  );

  if (isLoading || error) return <Loading />;

  if (data) {
    if (data.penilaian == null) {
      return <NotUpdate />;
    }
  }

  return (
    <div className="container mx-auto mt-[5.8rem] lg:mt-24">
      <div className="grid grid-cols-12 grid-flow-col gap-3">
        <div className="lg:col-start-4 lg:col-span-6 col-start-1 col-span-12 bg-white p-4 rounded-lg shadow-lg mx-4 lg:mx-0">
          <h2 className="text-center font-poppins-semibold text-2xl text-blue-600">
            Detail Hasil Tes Anda
          </h2>
          <p className="font-poppins-regular text-center text-sm mb-4 text-black">
            Berikut hasil tes Anda
          </p>

          <div className="grid grid-cols-5 text-xs font-poppins-regular gap-y-1 lg:mx-4 mb-4">
            <div className="col-span-2">NIK</div>
            <div className="col-span-3">{data.nik}</div>

            <div className="col-start-1 col-span-2">NAMA LENGKAP</div>
            <div className="col-span-3 font-poppins-semibold">
              {data.namaPemohon}
            </div>

            <div className="col-start-1 col-span-2">USIA</div>
            <div className="col-span-3">{data.usia}</div>

            <div className="col-start-1 col-span-2">JEN. KELAMIN</div>
            <div className="col-span-3">{data.jenKel}</div>

            <div className="col-start-1 col-span-2">NO. HP</div>
            <div className="col-span-3">{data.noHp}</div>

            <div className="col-start-1 col-span-2">ALAMAT</div>
            <div className="col-span-3">{data.alamat}</div>
          </div>

          <div className="relative overflow-x-auto mb-4">
            <table className="w-full text-sm text-left text-gray-500">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    Score
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Kategori
                  </th>
                </tr>
              </thead>
              <tbody>
                {data.penilaian.length > 0 &&
                  data.penilaian.map((row, index) => (
                    <tr key={`data-${index}`} className="bg-white border-b">
                      <th
                        scope="row"
                        className="px-4 py-2 font-poppins-regular text-xs text-gray-900 whitespace-nowrap"
                      >
                        {row.namaScoring}
                      </th>
                      <td className="px-4 py-2 text-xs font-poppins-semibold">
                        {row.namaKategori === "RENDAH" ||
                        row.namaKategori === "KURANG" ? (
                          <span className="text-red-500">
                            {row.namaKategori}
                          </span>
                        ) : (
                          <span className="text-emerald-500">
                            {row.namaKategori}
                          </span>
                        )}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>

          <p className="text-center font-poppins text-xs text-gray-400">
            REKOMENDASI
          </p>
          {data.hasilTes === "MS" ? (
            <h2 className="text-center font-poppins-semibold text-2xl text-emerald-600">
              MEMENUHI SYARAT
            </h2>
          ) : (
            <h2 className="text-center font-poppins-semibold text-2xl text-rose-600">
              BELUM MEMENUHI SYARAT
            </h2>
          )}

          <p className="font-poppins-semibold text-lg text-black text-center">
            SIM {data.kategoriSim} {data.jenSim.toUpperCase()}
          </p>

          <div>
            <div className="flex flex-row items-center justify-center mt-4">
              <div
                className="w-48 h-48"
                dangerouslySetInnerHTML={{ __html: data.qrcode }}
              />
            </div>
            <p className="text-center mt-4 text-xs text-gray-500 font-poppins-regular">
              Hasil tes berlaku selama 6 bulan sampai dengan: {data.tglExpired}
            </p>
            <div className="text-center mt-4 p-4 border bg-gray-100 lg:mx-10 rounded-lg">
              <p className="text-xs text-gray-400 font-poppins-regular">
                Biaya Tes PSIKOLOGI SIM
              </p>
              <p className="font-poppins-semibold text-2xl text-red-600">
                Rp60.000,-
              </p>
            </div>
            <div className="mt-4 lg:mx-10">
              <PDFDownloadLink
                document={<DocOfflinepdf data={data} />}
                fileName="keterangan-surat-lulus-psikologi-sim.pdf"
                className="text-white bg-[#e11d48] hover:bg-[#e11d48]/90 active::bg-[#e11d48]/50  focus:outline-none focus:ring-[#e11d48]/50 font-poppins-medium rounded-lg text-sm px-5 py-3 text-center inline-flex items-center justify-center shadow-md w-full"
              >
                {({ blob, url, loading, error }) =>
                  loading ? (
                    "Loading document..."
                  ) : (
                    <>
                      <span className="ml-2">
                        <HiOutlineDocumentDownload size={24} />
                      </span>
                      <span>Download Hasil Tes</span>
                    </>
                  )
                }
              </PDFDownloadLink>
            </div>
            <div className="bg-emerald-50 p-4 rounded-lg text-center text-sm font-poppins-regular text-emerald-500 mt-3 lg:mx-10">
              Serahkan qrcode ini kepada petugas psikologi sim untuk melakukan
              pembayaran dan pencetakkan persyaratan permohonan sim sesuai yang
              telah di pilih
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HasilOffline;
