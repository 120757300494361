import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import useSWR from "swr";
import { API_URL_TES } from "../../config";
import Loading from "../Loading";

const fetcher = (url) => fetch(url).then((r) => r.json());

const DetailRegister = () => {
  const { id } = useParams();

  const { data, error, isLoading } = useSWR(
    `${API_URL_TES}/pemohon/detail-register/${id}`,
    fetcher,
    {
      loadingTimeout: 3000,
    }
  );

  useEffect(() => {
    localStorage.removeItem("tokenTes");
  }, []);

  if (isLoading || error) return <Loading />;

  return (
    <div className="container mx-auto mt-[5.8rem] lg:mt-24">
      <div className="grid grid-cols-12 grid-flow-col gap-3">
        <div className="lg:col-start-4 lg:col-span-6 col-start-1 col-span-12 bg-white p-4 rounded-lg shadow-lg mx-4 lg:mx-0">
          <h2 className="text-center font-poppins-semibold text-2xl text-blue-600">
            Informasi Pemohon Tes
          </h2>
          <p className="font-poppins-regular text-center text-sm mb-4 text-black">
            Berikut detail pemohon tes psikologi sim
          </p>

          <div className="grid grid-cols-5 text-xs font-poppins-regular gap-y-1 lg:mx-4 mb-4">
            <div className="col-span-2">NIK</div>
            <div className="col-span-3">{data.nik}</div>

            <div className="col-start-1 col-span-2">NAMA lENGKAP</div>
            <div className="col-span-3 font-poppins-semibold">
              {data.namaLengkap}
            </div>

            <div className="col-start-1 col-span-2">USIA</div>
            <div className="col-span-3">{data.usia}</div>

            <div className="col-start-1 col-span-2">JEN. KELAMIN</div>
            <div className="col-span-3">{data.jenKel}</div>

            <div className="col-start-1 col-span-2">NO. HP</div>
            <div className="col-span-3">{data.noHp}</div>

            <div className="col-start-1 col-span-2">ALAMAT</div>
            <div className="col-span-3">{data.alamat}</div>
          </div>

          <p className="font-poppins-semibold text-lg text-black text-center">
            SIM {data.katSim} {data.jenSim.toUpperCase()}
          </p>

          <div>
            <div className="flex flex-row items-center justify-center mt-4">
              <div
                className="w-48 h-48"
                dangerouslySetInnerHTML={{ __html: data.qrcode }}
              />
            </div>
            <div className="text-center mt-4 p-4 border bg-gray-100 lg:mx-10 rounded-lg">
              <p className="text-xs text-gray-400 font-poppins-regular">
                Biaya Tes PSIKOLOGI SIM
              </p>
              <p className="font-poppins-semibold text-2xl text-red-600">
                {new Intl.NumberFormat("id-ID", {
                  style: "currency",
                  currency: "IDR",
                }).format(data.hargaTes)}
              </p>
            </div>
            <div className="bg-emerald-50 p-4 rounded-lg text-center text-sm font-poppins-regular text-emerald-500 mt-3 lg:mx-10">
              Tunjukan QRCode ini ke petugas kami untuk pengambilan buku soal
              dan lembar jawaban tes psikologi sim.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailRegister;
