import React from "react";
import { HiCheck } from "react-icons/hi";

const CardSim = ({ name, value, setValue, onClick }) => {
  return (
    <div
      className={
        value === setValue
          ? "flex-1 flex items-center rounded-[10px] bg-white px-4 py-5 border-2 border-blue-600 hover:border-2 hover:border-blue-600 cursor-pointer shadow-lg"
          : "flex-1 flex items-center rounded-[10px] bg-white px-4 py-5 border-2 border-gray-300 hover:border-2 hover:border-blue-600 cursor-pointer shadow-lg"
      }
      onClick={onClick}
    >
      {value === setValue ? (
        <span className="md:w-6 md:h-6 w-4 h-4 rounded-full bg-blue-500 shadow-lg mr-4 flex items-center justify-center">
          <HiCheck color="#FFFFFF" size={18} />
        </span>
      ) : (
        <span className="md:w-6 md:h-6 w-4 h-4 rounded-full bg-gray-300 mr-4"></span>
      )}

      <span className="md:text-lg text-sm font-poppins-bold text-gray-600">
        {name}
      </span>
    </div>
  );
};

export default CardSim;
